<template>
  <div class="appManagement_wrap">
    <div v-if="$route.path === '/company/apps'">
      <div class="topActions">
        <el-form :inline="true" :model="queryTerms" class="demo-form-inline">
          <div>
            <el-form-item label="">
              <el-input v-model="queryTerms.name" placeholder="请输入应用名称"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select clearable v-model="queryTerms.status" placeholder="请选择应用状态">
                <el-option label="待审核" value="0"></el-option>
                <el-option label="通过" value="1"></el-option>
                <el-option label="驳回" value="2"></el-option>
                <el-option label="已下线" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="common-btn" type="primary" @click="queryTable()">查询</el-button>

            </el-form-item>
          </div>
          <el-button class="common-btn app_publish" type="primary" @click='publishApp'>应用发布</el-button>
        </el-form>

      </div>
      <div v-loading='tableLoading'>
        <div class='app-wrap' v-if="appList&& appList.length > 0">
          <div class='app-item' v-for='(item) in appList' :key='item.id' @click='checkDetail(item)'>
            <div class='app-img'>
              <img :src="item.picurl" @error="imageLoadError(item)" :style="{ objectFit: item.objectFit||'contain' }" />

              <div class='app-tag-top'>{{ item.appTypeName }}</div>

            </div>
            <div class='app-item-label flex-column'>
              <div class='app-item-content'>
                <div class="title_wrap">
                  <span class='app-item-title'>{{ item.name }}</span>
                  <div class='app-item-status'> <span class='app-item-statusdot'
                      :style="{ backgroundColor: styleStatus(item.status).color }"></span>
                    <span class="statusText" :style="{ color: styleStatus(item.status).color }">
                      <template v-if="item.status === 2">
                        <el-tooltip class="item" effect="light" placement="top">
                          <template slot="content">
                            <span class="rejectContent_tooltip">{{item.rejectContent}}</span>
                          </template>
                          <span>{{ { 0: '待审核', 1: '通过', 2: '驳回', 3: '已下线' }[item.status] }}</span>
                        </el-tooltip>
                      </template>
                      <template v-else>
                        {{ { 0: '待审核', 1: '通过', 2: '驳回', 3: '已下线' }[item.status] }}
                      </template>
                    </span>
                  </div>
                </div>
                <el-tooltip placement="top" effect="light" v-if="item.introduce.length>50">
                  <template slot="content">
                    <span class="introduce_tooltip">{{item.introduce}}</span>
                  </template>
                  <div class="app-item-content item-content">{{ item.introduce }}</div>
                </el-tooltip>
                <div v-else class="app-item-content item-content">{{ item.introduce }}</div>
                <div class='app-tagList'>
                  <div class='app-tag' v-for='(e,index) in item.sceneTagNames' :key='index'>{{ e }}</div>
                </div>

              </div>
              <div class='app-item-content app-item-bottom'
                style='line-height:32px;display:flex;justify-content:space-between'>
                <span style='color:#909399'>{{ item.createTime }}</span>
                <div class='bottom-button'>
                  <el-tooltip effect="light" :content="item.status == 1 ? '下线' : '上线'" placement="bottom">
                    <el-button :icon="{ 1:'el-icon-open', 3:'el-icon-turn-off'}[item.status]" type='text'
                      @click='goliveOrOffline(item)' v-if='[1,3].includes(item.status)'></el-button>
                  </el-tooltip>
                  <el-tooltip effect="light" content="编辑" placement="bottom">
                    <el-button icon="el-icon-edit" type='text' @click='handleEditor(item)'
                      v-if='[1,2,3].includes(item.status)'></el-button>
                  </el-tooltip>
                  <el-tooltip effect="light" content="删除" placement="bottom">
                    <el-button icon="el-icon-delete" type='text' @click='handleDelete(item)'
                      v-if='item.status == 2||item.status == 3'></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>

      <div class="paginationBar" v-if='appList && appList.length > 0'>
        <el-pagination @current-change="changePage" :current-page.sync="page.pageNum" :page-size="page.pageSize"
          @size-change="sizeChange" layout="total, sizes, prev, pager, next" :total="page.total">
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
/* eslint-disable */
import api from '@/api/api'
import { EventBus } from '@/utils/eventBus';
import defaultImage from '@/assets/images/defaultImg.svg';
export default {
  created () {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // if (window.localStorage.getItem('searchKey')) {
    //   this.queryTerms.name = window.localStorage.getItem('searchKey')
    // }
    this.queryTable()
    // this.queryDictAppType()
    // this.queryDictSence()
  },
  data () {
    return {
      baseUrl: '/oss',
      banner: require('@/assets/images/banner.png'),
      imglist: [
        require('@/assets/images/banner.png')
      ],
      searchKey: '',
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      appTypeList: [],
      sceneList: [],
      appList: [],
      queryTerms: {
        name: '',
        appTypes: [],
        sceneTags: [],
        status: ''
      },
      tableLoading: false,
      defaultImage,
    }
  },
  computed: {
    styleStatus () {
      return function (status) {
        let styles = {
          0: { color: '#E6A23C' },
          1: { color: '#67C23A' },
          2: { color: '#F56C6C' },
          3: { color: '#909399' }
        };
        return styles[status] || {};
      }
    }
  },
  mounted () {
    EventBus.$on('updateData', () => {
      this.queryTable()
    });
  },
  beforeDestroy () {
    EventBus.$off('updateData');
  },
  methods: {
    imageLoadError (item) {
      this.$set(item, 'objectFit', 'cover');
      this.$set(item, 'picurl', this.defaultImage);
    },
    sizeChange (size) {
      this.page.pageSize = size
      this.queryTable()
    },
    changePage (page) {
      this.page.pageNum = page
      this.queryTable()
    },
    queryDictAppType () {
      api.queryDictDetail('app_type').then(res => {
        if (res.code == 200) {
          this.appTypeList = res.data
        }
      })
    },
    queryDictSence () {
      api.queryDictDetail('scene_tag').then(res => {
        if (res.code == 200) {
          this.sceneList = res.data
        }
      })
    },
    queryTable () {
      this.tableLoading = true
      api.queryApp({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        companyId: JSON.parse(window.localStorage.getItem('ecolUserInfo')).companyId,
        ...this.queryTerms
      }).then(res => {
        if (res.code == 200) {
          this.appList = res.data.list.map(item => {
            return {
              ...item,
              picurl: window.location.origin + '/ecol/app/image?path=/' + item.picture
            }
          })
          if (window.localStorage.searchKey) window.localStorage.removeItem('searchKey')
        }
        this.page.total = res.data.totalRows
        this.tableLoading = false
      })
        .catch(err => {
          this.tableLoading = false
        })
    },
    publishApp () {
      this.$router.push('/company/apps/publish')
    },
    selectItem (e, i, list) {
      if (list.indexOf(e.value) > -1) {
        list.splice(list.indexOf(e.value), 1)
      } else {
        list.push(e.value)
      }
      this.queryTable()
    },
    checkDetail (e) {
      // this.$router.push({
      //   path: '/appDetail',
      //   query: {
      //     id: e.id,
      //     companyName: e.companyName
      //   }
      // })
    },
    goliveOrOffline (item) {
      let text = item.status == 1 ? '下线' : '上线'
      this.$confirm(`是否要${text}该app?`, '提 示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(() => {
        let updown = item.status == 1 ? 3 : 1;
        api.appGoOffLine(item.id, updown).then(res => {
          if (res.code == 200) {
            this.$notify({
              title: '成 功',
              message: '操作成功',
              type: 'success'
            });
            this.queryTable()
          }
        })
      }).catch(() => {

      });

    },
    handleEditor (e) {
      this.$router.push({
        path: '/company/apps/publish',
        query: {
          id: e.id
        }
      })
    },
    handleDelete (item) {
      this.$confirm('确定要删除该条应用么?', '提 示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(() => {
        api.deleteApp(item.id).then(res => {
          if (res.code == 200) {
            this.$notify({
              title: '成 功',
              message: '操作成功',
              type: 'success'
            });
            this.queryTable()
          }
        })
      }).catch(() => {

      });
    },
  }
}
</script>

<style lang="scss">
.appManagement_wrap {
  .topActions {
    position: relative;
    .demo-form-inline {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .el-form-item {
        width: 240px;
        height: 40px;
        .el-input__inner {
          width: 240px;
          height: 40px;
          border-radius: 0px;
        }
      }
    }
  }
  .statusDot {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-right: 6px;
  }
  .label {
    color: #a8a8a8;
    font-weight: 600;
    line-height: 24px;
    font-size: 14px;
  }
  .title {
    height: 32px;
    line-height: 32px;
    margin-top: 16px;
    font-size: 16px;
    color: #333;
  }
  .app-wrap {
    width: 100%;
    min-height: 700px;
    margin: 16px 0;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .app-item {
    width: calc(20% - 50px);
    height: 300px;
    margin: 12px;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 2px;
    border: 1px solid #dfe3e6;
    cursor: pointer;
  }
  .app-tag-top {
    position: absolute;
    padding: 5px 10px;
    background: rgba(38, 78, 166, 0.95);
    border-radius: 2px;
    text-align: center;
    top: 7px;
    right: 7px;
    color: #fff;
    font-size: 12px;
  }
  .app-tagList {
    width: 100%;
    display: flex;
    margin-top: 10px;
    .app-tag {
      background: rgba(34, 78, 175, 0.1);
      border-radius: 4px;
      height: 30px;
      line-height: 30px;
      color: #353e4f;
      font-size: 14px;
      padding-left: 6px;
      padding-right: 6px;
      margin-right: 10px;
    }
  }

  .app-item-label {
    width: calc(100% - 16px);
    padding: 8px;
    height: calc(50% - 12px);
    position: relative;
  }
  .app-img {
    width: 100%;
    height: 50%;
    position: relative;
    border: 1px solid #f1eaea;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .app-item-content {
    margin: 4px 0;
    font-size: 14px;
    color: #333;
  }
  .app-item-bottom {
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    color: #909399;
    text-align: right;
    width: calc(100% - 12px);
    border-top: 1px solid #ebeef5;
  }
  .app-item-title {
    color: #224eaf;
    font-size: 18px;
    font-weight: 500;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .item-content {
    color: #606266;
    font-size: 12px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .app-item-status {
    font-size: 14px;
    color: #909399;
  }
  .app-item-statusdot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    margin-right: 4px;
    display: inline-block;
  }
  .select-item {
    padding: 0 8px;
    margin-right: 4px;
    border-radius: 4px;
    cursor: pointer;
    color: #333;
  }
  .bottom-button {
    i {
      color: #000;
      font-size: 16px;
    }
  }
  .active {
    background-color: #013293;
    color: #fff;
  }
  .el-icon-s-order,
  .el-icon-s-check {
    font-size: 18px;
    color: #909399;
    position: relative;
    right: 12px;
  }

  .paginationBar {
    text-align: right;

    .el-pager li.active {
      color: #fff;
      cursor: default;
    }
  }
}

.introduce_tooltip,.rejectContent_tooltip {
  max-width: 250px;
  background: #fff;
  color: #000;
  display: inline-block;
 
}

</style>

